import { domain } from 'config';
import './story_card.css';

import React from 'react';
import { Link } from 'react-router-dom';

const StoryCard = ({
    className = '',
    background = '#fff',
    title,
    link = '#',
    description,
    emoji,
    image,
    images,
    IsMaskPreview,
    IsPartner = null,
}: {
    className?: any;
    background?: any;
    title?: any;
    link?: string;
    description?: any;
    emoji?: any;
    image?: any;
    images?: any;
    IsMaskPreview?: any;
    IsPartner?: any;
}) => {
    return (
        <Link to={link} className={`story-card _default ${className}`}>
            <div
                className="story-card__wrap"
                style={{ background: background }}
            >
                <div className="story-card__common">
                    {IsPartner && (
                        <span className="tag _yellow">Партнерский пост</span>
                    )}
                    {emoji && (
                        <div
                            className="story-card__emoji"
                            style={{ backgroundImage: 'url(' + emoji + ')' }}
                        ></div>
                    )}
                    {className.indexOf('_guide') < 0 && image && (
                        <div
                            className={`story-card__image ${
                                (IsMaskPreview && '_rounded') || ''
                            }`}
                            style={{ backgroundImage: 'url(' + image + ')' }}
                        ></div>
                    )}
                    {images && (
                        <div className="story-card__images">
                            {images.map((image?: any, index?: number) => {
                                return (
                                    <div
                                        key={index}
                                        className="story-card__images-item"
                                        style={{
                                            backgroundImage:
                                                'url(' +
                                                domain +
                                                image.attributes.url +
                                                ')',
                                        }}
                                    ></div>
                                );
                            })}
                        </div>
                    )}
                    {title && <h4 className="story-card__title">{title}</h4>}
                    {description && (
                        <div className="story-card__description">
                            {description}
                        </div>
                    )}
                </div>
                {className.indexOf('_guide') >= 0 && image && (
                    <div
                        className="story-card__illustration"
                        style={{ backgroundImage: 'url(' + image + ')' }}
                    ></div>
                )}
            </div>
        </Link>
    );
};

export default StoryCard;
